import styled, { css, keyframes } from "styled-components";

// Utility function to generate keyframes for each petal based on its index
const generatePetalAnimation = (index: number) => keyframes`
  0% { transform: rotate(0); }
  100% { transform: rotate(${index * 30}deg); }
`;

// Utility function to generate reverse keyframes
const generateReverseAnimation = (index: number) => keyframes`
  0% { transform: rotate(${index * 30}deg); }
  100% { transform: rotate(360deg); }
`;

const totalPetals = 12;
const duration = 1.5;

// Create an array of animation styles dynamically for each petal
const petalAnimations = Array.from({ length: totalPetals }, (_, i) => css`
  :nth-child(${i + 1}) {
    animation: ${generatePetalAnimation(i + 1)} ${(i + 1) * (duration / totalPetals)}s ease-in-out 0s forwards,
               ${generateReverseAnimation(i + 1)} ${(totalPetals - i) * (duration / totalPetals)}s ease-in-out ${(i - 2) * (duration / totalPetals) + duration}s forwards;
  }
`);

export const Svg = styled.svg<{ $active?: boolean }>`
  width: 48px;
  transform: rotate(30deg);

  ${({ $active }) => $active && css`
    path {
      ${petalAnimations};
    }
  `};
`;

export const Path = styled.path`
  opacity: 0.4;
  fill: #fff;
  enable-background: new;
  transform-origin: 50%;
`;
