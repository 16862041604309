import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Spinner } from "elements";

interface RootState {
  loading: boolean;
  listAreaReducer: { loading: boolean };
  listCategoryReducer: { loading: boolean };
  selectAreaAndCategoriesReducer: { loading: boolean };
  domainListReducer: { loading: boolean };
  deleteUserAreaReducer: { loading: boolean };
  deleteActionReducer: { loading: boolean };
  accountabilityReducer: { loading: boolean };
  createActionReducer: { loading: boolean };
  prioritizeActionPlansReducer: { loading: boolean };

  // General
  getMyClientListReducer: { loading: boolean };

  // APM
  listFocusedPrioritise: { loading: boolean };
  unprioritizedReducer: { loading: boolean };
  RetrieveMasterPlanDetailsReducer: { loading: boolean };
  updateTimeLine: { loading: boolean };
  weeklyDuration: { loading: boolean };
  prioritizedActions: { loading: boolean };
  createPrioritizedActivities: { loading: boolean };
  availableAreas: { loading: boolean };
  updateIndirectScores: { loading: boolean };
  updateFocusedPriorities: { loading: boolean };
  prioritizedtoFocused: { loading: boolean };
  createAction: { loading: boolean };
  createTask: { loading: boolean };
}

const Loading: React.FC = () => {
  const { loading: listArealoading } = useSelector(
    (state:RootState) => state.listAreaReducer
  );
  const { loading: listCategoryLoading } = useSelector(
    (state:RootState) => state.listCategoryReducer
  );
  const { loading: selectAreaAndCategoriesLoading } = useSelector(
    (state:RootState) => state.selectAreaAndCategoriesReducer
  );
  const { loading: masterPlanDetailsLoading } = useSelector(
    (state:RootState) => state.RetrieveMasterPlanDetailsReducer
  );
  const { loading: domainListLoading } = useSelector(
    (state:RootState) => state.domainListReducer
  );
  const { loading: deleteUserAreaLoading } = useSelector(
    (state:RootState) => state.deleteUserAreaReducer
  );
  const { loading: deleteActionLoading } = useSelector(
    (state:RootState) => state.deleteActionReducer
  );
  const { loading: listAccountabilityLoading } = useSelector(
    (state:RootState) => state.accountabilityReducer
  );
  const { loading: createActionItemLoading } = useSelector(
    (state:RootState) => state.createActionReducer
  );
  const { loading: prioritizeActionItemsLoading } = useSelector(
    (state:RootState) => state.prioritizeActionPlansReducer
  );

  // general
  const { loading: getMyClientListLoading } = useSelector(
    (state:RootState) => state.getMyClientListReducer
  );

  // apm loader
  const { loading: listFocusedPrioritise } = useSelector(
    (state:RootState) => state.listFocusedPrioritise
  );
  const { loading: unprioritizedReducer } = useSelector(
    (state:RootState) => state.unprioritizedReducer
  );
  const { loading: updateTimeLine } = useSelector(
    (state:RootState) => state.updateTimeLine
  );
  const { loading: weeklyDuration } = useSelector(
    (state:RootState) => state.weeklyDuration
  );
  const { loading: prioritizedActions } = useSelector(
    (state:RootState) => state.prioritizedActions
  );
  const { loading: createPrioritizedActivities } = useSelector(
    (state:RootState) => state.createPrioritizedActivities
  );
  const { loading: availableAreas } = useSelector(
    (state:RootState) => state.availableAreas
  );
  const { loading: updateIndirectScores } = useSelector(
    (state:RootState) => state.updateIndirectScores
  );
  const { loading: updateFocusedPriorities } = useSelector(
    (state:RootState) => state.updateFocusedPriorities
  );
  const { loading: prioritizedtoFocused } = useSelector(
    (state:RootState) => state.prioritizedtoFocused
  );
  const { loading: createAction } = useSelector(
    (state:RootState) => state.createAction
  );
  const { loading: createTask } = useSelector(
    (state:RootState) => state.createTask
  );

  const isLoading = [
    listAccountabilityLoading,
    listCategoryLoading,
    listArealoading,
    selectAreaAndCategoriesLoading,
    masterPlanDetailsLoading,
    domainListLoading,
    deleteUserAreaLoading,
    deleteActionLoading,
    createActionItemLoading,
    prioritizeActionItemsLoading,

    // general
    getMyClientListLoading,

    // apm loader
    listFocusedPrioritise,
    unprioritizedReducer,
    updateTimeLine,
    weeklyDuration,
    prioritizedActions,
    createPrioritizedActivities,
    availableAreas,
    updateIndirectScores,
    updateFocusedPriorities,
    prioritizedtoFocused,
    createAction,
    createTask
  ];

  const [isAnimate, setIsAnimate] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimate(false);

      setTimeout(() => {
        setIsAnimate(true);
      }, 200);
    }, 2800);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      {isLoading?.includes(true) && (
        <Wrapper>
          <Spinner />
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #00000049;
  z-index: 1100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  z-index: 9999999;
`;

export default Loading;
