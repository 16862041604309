import { useEffect, useRef, useState } from "react";
import { InputField } from "elements";
import { Dropdown } from "antd";

import InputAreaAI from "components/InputAreaAI";

import { ReactComponent as ChevronRightIcon } from "assets/images/journeys/chevron-right.svg";
import { ReactComponent as PlusSmIcon } from "assets/images/journeys/plus-sm.svg";
import { ReactComponent as GearIcon } from "assets/images/journeys/gear.svg";

import { Wrapper, Body, Footer, Col, Text, DropdownToggle } from './styled';

import styles from './styles.module.scss';

const UnitsSelector = ({ id, predefinedData, customData, goalData, assistant, onClick, onChange, onOpen }) => {
  const $inputStart = useRef(null);
  const $dropdownToggle = useRef(null);

  const [settings, setSettings] = useState({
    current: '',
    start: '',
    target: ''
  });

  useEffect(() => {
    if ($dropdownToggle && $dropdownToggle.current && !goalData.measurement_metric_name)
      $dropdownToggle.current.focus();
    else if ($inputStart && $inputStart.current)
      $inputStart.current.focus();
  }, [id, goalData.measurement_metric_name]);

  useEffect(() => {
    if (!settings.current.length && goalData.measurement_current_value?.toString()) {
      setSettings(prev => ({
        ...prev,
        current: goalData.measurement_current_value
      }));
    }
  }, [goalData?.measurement_current_value]);

  useEffect(() => {
    if (!settings.start.length && goalData.measurement_start_value?.toString()) {
      setSettings(prev => ({
        ...prev,
        start: goalData.measurement_start_value
      }));
    }
  }, [goalData?.measurement_start_value]);

  useEffect(() => {
    if (!settings.target.length && goalData.measurement_target_value?.toString()) {
      setSettings(prev => ({
        ...prev,
        target: goalData.measurement_target_value
      }));
    }
  }, [goalData?.measurement_target_value]);

  const {
    measurement_metric_name: name
  } = goalData;

  const handleClick = e => {
    onClick(e);
  }

  const handleChange = field => e => {
    setSettings(prev => ({
      ...prev,
      [field]: e.target.value
    }));
  }

  const handleBlur = field => e => {
    onChange(`measurement_${field}_value`, e.target.value.length ? e.target.value : null);
  }

  const handleKeyPress = field => e => {
    if (e.key === "Enter") {
      onChange(`measurement_${field}_value`, e.target.value.length ? e.target.value : null);
    }
  }

  const listOfUnits = [
    ...predefinedData.map(item => ({
      key: item.id,
      children: item.units.map(unit => ({ key: unit.id, label: unit.name })),
      label: item.label
    })),
    ...customData.length ? [{
      label: "Custom Metrics",
      type: 'group'
    }] : [],
    ...customData.map(item => ({
      key: item.id,
      children: item.units.map(unit => ({ key: unit.id, label: unit.name })),
      label: item.label
    })),
    customData.length
      ? {
        key: 'manage',
        label: (
          <>
            Manage Metrics
            <GearIcon />
          </>
        )
      }
      : {
        key: 'custom',
        label: (
          <>
            Add Custom
            <PlusSmIcon />
          </>
        )
      }
  ];

  const keyOfMatchingElement = Object.values(listOfUnits.filter(item => item.children)).reduce((acc, unit) => {
    if (acc)
      return acc;
    const foundChild = unit.children.find(child => child.label === goalData?.measurement_metric_name);
    return foundChild ? foundChild.key : null;
  }, null);

  return (
    <Wrapper>
      <Body>
        <Col>
          <Text>Metric</Text>
          <Dropdown menu={{ items: listOfUnits, selectedKeys: [keyOfMatchingElement], onClick: handleClick/* , openKeys: ["4972dd78-6104-4656-9fe9-44e98b82c827"] */ }} trigger={['click']} overlayClassName={styles.dropdown}>
            <DropdownToggle title={name || 'Select'} ref={$dropdownToggle}>
              {name ? <span>{name}</span> : <span className="default">Select</span>}
              <ChevronRightIcon />
            </DropdownToggle>
          </Dropdown>
        </Col>
        <Col $flex={1}>
          <Text>Start</Text>
          <InputField placeholder="-" value={settings.start} disabled={!name} onChange={handleChange('start')} onBlur={handleBlur('start')} onKeyPress={handleKeyPress('start')} /* suffix={name} */ ref={$inputStart} />
        </Col>
        <Col $flex={1}>
          <Text>Current</Text>
          <InputField placeholder="-" value={settings.current} disabled={!name} onChange={handleChange('current')} onBlur={handleBlur('current')} onKeyPress={handleKeyPress('current')} /* suffix={name} */ />
        </Col>
        <Col $flex={1}>
          <Text>Target</Text>
          <InputField placeholder="-" value={settings.target} disabled={!name} onChange={handleChange('target')} onBlur={handleBlur('target')} onKeyPress={handleKeyPress('target')} /* suffix={name} */ />
        </Col>
      </Body>

      {assistant ? <Footer>
        <InputAreaAI
          assistant={assistant}
          onlyButton
          onOpen={onOpen}
          readOnly
        />
      </Footer> : null}
    </Wrapper>
  );
}

export default UnitsSelector;
