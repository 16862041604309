import styled from "styled-components";

import { Row } from "antd";

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  min-width: 360px;
  max-width: 600px;
  padding: 14px 0 14px 14px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ isBetween }) => isBetween ? "space-between" : "flex-start"};
  gap: ${({ isheader }) => (isheader ? "0.5rem" : "1rem")};
  width: 100%;
  height: ${({ isheader }) => isheader && "35px"};
  min-height: ${({ isheader }) => isheader && "35px"};
  padding: ${({ isheader }) => isheader && "0 14px 0 0"};
  border-bottom: 1px solid #d9d9d9;
`;

export const Image = styled.img``;

export const Heading = styled.h2`
  margin: 0;
  padding: 0;
  color: #112538;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1.68px;
`;

export const StatmentContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-direction: column;
  overflow-y: scroll;
  height: ${({ isHeight }) => (isHeight ? "500px" : "430px")};

  ${({ theme }) => theme.min("sm")`
    ::-webkit-scrollbar {
      width: 20px;
    }

    ::-webkit-scrollbar-track {
      background: transparent !important;
    }
    
    ::-webkit-scrollbar-thumb {
      border: 8px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: #D9D9D9;
    }
  `}
`;

export const LargeHeading = styled.h1`
  margin: 0;
  padding: 0;
  color: #4c545b;
  text-align: center;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

export const Text = styled.p`
  margin: 0;
  padding: 0 12px;
  color: var(--Input-header, #737373);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

export const CustomButton = styled.button`
  border: none;
  outline: none;
  display: flex;
  width: 279px;
  height: 36px;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  background: #1271a6;
  border-radius: 3px;
  color: #fff;
  margin-top: 1.5rem;
`;

export const Footer = styled.div`
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 0.5rem;
`;

export const FooterHeading = styled.h2`
  margin: 0;
  padding: 0;
  color: #171717;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
`;

export const Span = styled.span`
  color: #4cbac9;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
`;

// ROles Section //
export const RolesContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const RolesHeading = styled.div`
  display: flex;
  align-items: center;

  ${({ $printable }) => $printable && `
    padding: 12px 0;
    border-top: 1px solid var(--Placeholder, #A3A3A3);
    border-bottom: 1px solid var(--Placeholder, #A3A3A3);

    @media not print {
      display: none;
    }
  `};
`;

export const MissionHeading = styled.h3`
  margin: 0;
  color: #262626;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;

export const RolesContent = styled.div`
  width: calc(100% - 6px);
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
  padding-top: 1rem;
  overflow-y: scroll;

   ${({ theme }) => theme.min("sm")`
    ::-webkit-scrollbar {
      width: 20px;
    }

    ::-webkit-scrollbar-track {
      background: transparent !important;
    }
    
    ::-webkit-scrollbar-thumb {
      border: 8px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: #D9D9D9;
    }
  `}
`;

export const Circle = styled.div`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  background-color: #ecf3f6;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1689ca;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-right: 12px;
`;

export const MainRoleDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

export const MissionArea = styled.h3`
  color: #525252;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  margin: 0;
  white-space: nowrap;
`;

export const RolesSpan = styled.h3`
  color: #525252;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  min-width: ${({ isEllipse }) => isEllipse && "150px"};
  max-width: ${({ isEllipse }) => isEllipse && "280px"};
`;

export const RoleHeading = styled.span`
  color: #171717;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 120px;
  max-width: 250px;
  margin-right: 8px;

  @media print {
    min-width: initial;
    max-width: initial;
  }
`;

export const StepsWrapper = styled.div`
  width: 90%;
`;

export const XlargeHeading = styled.h2`
  color: #112538;
  text-align: center;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NoitSpan = styled.span`
  color: rgba(142, 151, 163, 1);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  border: 1px solid;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-top: 0.2rem;
`;

export const RolesText = styled.p`
  color: #404040;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

export const TextsWrapper = styled.div``;

export const SmallText = styled.p`
  color: #737373;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

export const SelectWrapper = styled.div``;

export const AiSuggestionContianer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 0.5rem;

  .ant-switch-checked {
    background-color: #1271a6 !important;
  }
`;

export const ChipsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
`;

export const RegGernerateButton = styled.button`
  color: #1271a6;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
`;

export const EditStatmentButton = styled.button`
  color: #404040;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border: none;
  outline: none;
  background-color: transparent;
  display: flex;
  gap: 0.3rem;
  align-items: center;
  cursor: pointer;
`;

export const InputWrapper = styled.div`
  width: 100%;
`;

export const AiSugesstionText = styled.div`
  width: 100%;
  display: flex;
  padding: ${({ isLight }) => (isLight ? "0" : "6px 10px")};
  align-items: center;
  color: #525252;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  background-color: ${({ isLight }) => (isLight ? "#fff" : "#f5f5f5")};
`;

export const Wrapper = styled.div`
  width: calc(100% - 14px);
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 1rem;
  border-top: ${({ isBorder }) => isBorder && "1px solid #D9D9D9"};
  padding-top: ${({ isBorder }) => isBorder && "0.5rem "};
`;

export const MissionComplete = styled.div`
  width: calc(100% - 6px);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 10px;
  overflow-y: scroll;

  @media print {
    padding: 20px;
    overflow: hidden;
  }

  @media not print {
    max-height: 600px;

    ${({ theme }) => theme.min("sm")`
      ::-webkit-scrollbar {
        width: 20px;
      }

      ::-webkit-scrollbar-track {
        background: transparent !important;
      }
      
      ::-webkit-scrollbar-thumb {
        border: 8px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 9999px;
        background-color: #D9D9D9;
      }
    `}
  }
`;

export const CustomTable = styled.div`
  width: 100%;
  padding: 13px 10px;
  background-color: #f5f5f5;
  border: 1px solid #cdcdcd;
`;

export const CustomRow = styled(Row)`
  display: flex;
  flex-direction: ${({ isRow }) => (isRow ? "none" : "column")};
  border-bottom: ${({ isBorderNone }) => isBorderNone ? "none" : "1px solid #d9d9d9"};
  padding: 0.5rem;
  gap: ${({ isGap }) => (isGap ? "" : "0.25rem")};
`;

export const RowHeading = styled.h2`
  margin: 0;
  padding: 0;
  color: #262626;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;

export const RowText = styled.p`
  margin: 0;
  padding: 0;
  color: #4c545b;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

export const DropdownWrapper = styled.div`
  width: 1.2rem;
`;
