import React, { useEffect, useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";
import Loading from "components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getCookie } from "utils/StorageVariables";
import { IsUser } from "Redux/User/action";
import { RetrieveMasterPlanDetails } from "Redux/RetrieveMasterPlan/action";
import SubHeader from "components/Scheduler/SubHeader";
import StepsHeader from "../StepsHeader";
import AreasSection from "../AreasSection";

import {
  ACTIONS_PATH,
  GOALS_PATH,
  JOURNEY_PATH,
  PREMISE_PATH,
  MASTER_PATH,
  MEASUREMENT_PATH,
  MISSION_PATH,
  PRIORITIZE_PATH,
  PURPOSE_PATH,
} from "utils/routes";
import GoalsSection from "../GoalsSection";
import InstructionsNav from "components/InstructionsNav";

import { items } from "./data";
import Sidebar from "../Sidebar";

const JourneysLayout = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { userDetail } = useSelector((state) => state.userDetailReducer);
  const { listArea } = useSelector((state) => state.listAreaReducer);

  useEffect(() => {
    let MasterPlanId = getCookie("MasterPlanId");
    if (!MasterPlanId) return;
    dispatch(RetrieveMasterPlanDetails(MasterPlanId));
  }, []);

  useEffect(() => {
    if (userDetail === null) {
      let userData = JSON.parse(getCookie("userData"));
      dispatch(IsUser(userData));
    }
  }, [userDetail]);

  const step = useMemo(() => {
    switch (location.pathname) {
      case MISSION_PATH:
        return 1;
      case PRIORITIZE_PATH:
        return 2;
      case GOALS_PATH:
        return 3;
      case MEASUREMENT_PATH:
        return 4;
      case PURPOSE_PATH:
        return 5;
      case ACTIONS_PATH:
        return 6;
      case MASTER_PATH:
        return 10;
    }

    return 0;
  }, [location.pathname]);

  return (
    <Wrapper>
      <Loading />

      {(location.pathname !== JOURNEY_PATH && location.pathname !== PREMISE_PATH) && <SubHeader title="Goal Setting Process">
        <StepsHeader items={items} step={step} withLinks withLabels />
      </SubHeader>}

      <PagesWrapper>
        {step === 1 || step === 2 || step === 10 ? (
          <Sidebar title="Area Descriptions" secondary>
            <AreasSection
              areas={listArea && listArea[0]?.areas ? listArea[0].areas : []}
            />
          </Sidebar>
        ) : step === 0 ? null : (
          <GoalsSection step={step} />
        )}
        <PageLayout>
          <Outlet />
        </PageLayout>
      </PagesWrapper>

      <InstructionsNav step={step} />
    </Wrapper>
  );
};

export default JourneysLayout;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: calc(100vh - 60px);
  // padding-left: 66px;
`;

const PagesWrapper = styled.div`
  display: flex;
  flex: 1;
  height: calc(100vh - 106px);
  width: 100%;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 1439px) {
    height: calc(100vh - 118px);
  }

  ${({ theme }) => theme.max("sm")`
    height: calc(100vh - 60px);
  }`}
`;

const PageLayout = styled.div`
  flex: 1;
  height: calc(100% - 60px - 50px);

  ${({ theme }) => theme.max("sm")`
    height: calc(100% - 56px);
  }`}
`;
