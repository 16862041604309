import { FC, useEffect, useState } from "react";
import moment from "moment";
import { Tooltip } from "elements";

import {
  Wrapper,
  Goal,
  GoalLabel,
  GoalTitle,
  GoalProgress,
  ItemWrapper,
  Item,
  ItemCol,
  ItemTitle,
  ItemData,
  ItemDataTitle,
  ItemDataValue,
  ItemDeleteBtn,
} from "./styled";

import { Hla, Type } from "../../../Elements";

import RadialBar from "components/PriorityApp/SimpleView/RadialBar";
import GoalPopover from "components/PriorityApp/SimpleView/GoalPopover";

// @ts-ignore
import { ReactComponent as CloseIcon } from "assets/images/priorities/close.svg";

// @ts-ignore
import { ReactComponent as RestoreIcon } from "assets/images/priorities/restore.svg";

import { IPrioritizedActivity, ITask } from "Redux/APM/ListFocusedExtremes/type";
import DurationForm from "../DurationForm";

interface IProps {
  id?: string;
  activity: IPrioritizedActivity | null;
  task: ITask | null;
  priority: number;
  showGoal?: boolean;
  showDetails?: boolean;
  adjustDuration?: boolean;
  showRemoveBtn?: boolean;
  onTimeChange?: (id: string, activity: string, value: number, original: number) => void;
  onRemove?: (id: string, time: number) => void;
}

/**
 * Retrieves the value from either 'activity' or 'task' based on given keys, or returns a default value.
 * @returns {*} The value from either activity or task, or the default value.
 */
/* const getDefault = (
  activity: IPrioritizedActivity | null,
  task: ITask | null,
  activityKey: string,
  taskKey: string = '',  // Providing a default value here to avoid 'undefined'.
  defaultValue: string
): string => {
  if (!taskKey) taskKey = activityKey;  // Ensure taskKey is never undefined.
  return activity?.[activityKey] ?? task?.[taskKey] ?? defaultValue;
};
 */
const ActivityItem: FC<IProps> = ({
  id,
  activity,
  task,
  priority,
  showGoal,
  showDetails,
  adjustDuration,
  showRemoveBtn,
  onTimeChange,
  onRemove,
}) => {
  const [time, setTime] = useState(0);
  const [isDeleted, setIsDeleted] = useState(false);

  const itemId = activity ? activity.id : task ? task.id : '';
  const itemName = activity ? activity.action.context : task ? task.name : '';
  const itemTime = activity ? activity.total_time : task ? task.estimated_duration : 0;
  const itemType = activity ? activity.action.type : task ? 'Task' : '';
  const itemDeadline = activity ? activity.deadline : task ? task.deadline : '';

  const goal_gap_percentage = activity ? activity.goal_gap_percentage : 0;
  const goal_name = activity ? activity.goal_name : '';
  const goal_color = activity ? activity.color : '';

  const goalProgress = goal_gap_percentage
    ? Math.ceil(Math.abs(100 - goal_gap_percentage))
    : 0;

  const goalPopoverData = {
    area_name: activity?.area_name,
    action: { context: itemName },
    category_name: activity?.category_name,
    goal_name: goal_name,
    color: goal_color
  }

  useEffect(() => {
    setTime(itemTime);
  }, [itemTime]);

  const handleAfterTimeChange = (value: number) => {
    if (onTimeChange && id) {
      onTimeChange(id, itemId, value, itemTime);
      // onTimeChange(itemId, value, itemTime);
    }
  };

  const handleDelete = () => {
    if (onRemove && id) {
      setIsDeleted((prev) => !prev);
      onRemove(id, itemTime);
    }
  };

  return (
    <Wrapper>
      {showGoal ? (
        <Goal $color={goal_color}>
          <GoalLabel>Goal</GoalLabel>
          <GoalTitle>{goal_name || 'Unnamed goal'}</GoalTitle>
        </Goal>
      ) : null}

      <ItemWrapper $active={showGoal}>
        <Item $active={showGoal} $deleted={isDeleted}>
          <ItemCol>
            {showDetails ? null : (
              <GoalProgress>
                <Tooltip title="Goal Progress">
                  <div>
                    <RadialBar value={goalProgress} />
                  </div>
                </Tooltip>
              </GoalProgress>
            )}

            {/* <ItemOrder>{priority}</ItemOrder> */}
            <Type type={itemType} />
            <ItemTitle title={itemName}>{itemName}</ItemTitle>

            {activity && !showGoal ? <GoalPopover data={goalPopoverData} /> : null}

            {activity && parseInt(activity.indirect_impact) > 5 ? (
              <Hla indirectImpact={parseInt(activity.indirect_impact)} />
            ) : null}
          </ItemCol>

          {showDetails ? (
            <ItemCol $secondary>
              <ItemData>
                <ItemDataTitle>Deadline</ItemDataTitle>
                <ItemDataValue>
                  {itemDeadline
                    ? moment(itemDeadline).format("MM/DD/YY")
                    : "-"}
                </ItemDataValue>
              </ItemData>

              <ItemData>
                <ItemDataTitle>Est. Duration</ItemDataTitle>
                <DurationForm
                  value={time}
                  onChange={handleAfterTimeChange}
                />
              </ItemData>
            </ItemCol>
          ) : null}

          {adjustDuration ? (
            <ItemCol $tertiary>
              <DurationForm
                value={time}
                max={itemTime}
                disabled={isDeleted || !time}
                showSlider
                onChange={handleAfterTimeChange}
              />

              <ItemDeleteBtn
                title={isDeleted ? "Restore" : "Remove from Focused"}
                onClick={handleDelete}
                $disabled={!showRemoveBtn}
              >
                {isDeleted ? <RestoreIcon /> : <CloseIcon />}
              </ItemDeleteBtn>
            </ItemCol>
          ) : null}
        </Item>
      </ItemWrapper>
    </Wrapper>
  );
};

export default ActivityItem;
