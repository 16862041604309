import { useEffect, useRef, useState } from "react";
import { Button, InputField } from "elements";
import { Accordion } from "react-bootstrap";

import { ReactComponent as CollapseIcon } from "assets/images/journeys/chevron-right.svg";
import { ReactComponent as TrashBlueIcon } from "assets/images/journeys/trash-blue.svg";
import { ReactComponent as TrashDarkIcon } from "assets/images/journeys/trash-dark.svg";

import {
  Wrapper,
  Head,
  Toggle,
  Title,
  Delete,
  Body,
  BodyInner,
  // DeleteBtn
} from "./styled";

import {
  Group,
  Label,
  ActionsGroup
} from '../styled';

const MetricGroup = ({ activeKey, data, onToggle, onUnitAdd, onUnitUpdate, onUnitDelete, onMetricUpdate, onMetricDelete }) => {
  const { id, label, units } = data;

  const [isUnitCreation, setIsUnitCreation] = useState(false);
  const [isUnitEdit, setIsUnitEdit] = useState(null);

  const [unitName, setUnitName] = useState('');
  const [labelName, setLabelName] = useState(label);

  const $label = useRef(null);
  const $unit = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (activeKey && $label && $label.current)
        $label.current.focus();
    }, 350);
  }, [activeKey]);

  const onClickHandle = () => {
    onToggle(id);
  }

  const onLabelChangeHandle = e => {
    let input = e.target.value.trim();

    if (input.length > 12) {
      input = input.slice(0, 12);
    }

    setLabelName(input);
  }

  const onLabelBlurHandle = e => {
    if (e.target.value === label)
      return;

    onMetricUpdate(e.target.value, id);
  }

  const onMetricDeleteHandle = () => {
    onMetricDelete(id);
  }

  const onDeleteHandle = (unit) => () => {
    if (!unit?.id)
      setIsUnitCreation(false);
    else
      onUnitDelete(unit.id);
  }

  const onAddUnitHandle = () => {
    setIsUnitCreation(true);

    setTimeout(() => {
      if ($unit && $unit.current)
        $unit.current.focus();
    }, 150);
  }

  const onUnitChangeHandle = e => {
    let input = e.target.value.trim();

    if (input.length > 17) {
      input = input.slice(0, 17);
    }

    setUnitName(input);
  }

  const onUnitFocusHandle = unit => () => {
    setUnitName(unit.name);
    setIsUnitEdit(unit.id);
  }

  const onUnitBlurHandle = unit => e => {
    setUnitName('')
    setIsUnitEdit(null);

    onUnitUpdate(e.target.value, unit.id)
  }

  const onNewUnitChangeHandle = e => {
    let input = e.target.value.trim();

    if (input.length > 12) {
      input = input.slice(0, 12);
    }

    setUnitName(input);
  }

  const onNewUnitBlurHandle = e => {
    if (!unitName.length || e?.relatedTarget?.classList?.contains("ghost")) {
      setIsUnitCreation(false);
      setUnitName('');

      return;
    }

    onUnitAdd(e.target.value, id);

    setIsUnitCreation(false);
    setUnitName('');
  }

  const contentSuffix = unit => (
    <Delete type="button" className={unit === 0 ? 'ghost' : ''} title="Delete Unit" onClick={onDeleteHandle(unit)}>
      <TrashDarkIcon />
    </Delete>
  );

  const active = activeKey === id;

  return (
    <Wrapper $active={active}>
      <Head>
        <Toggle $active={active} type="button" onClick={onClickHandle}>
          <CollapseIcon />
          <Title $active={active}>{label}</Title>
        </Toggle>

        {active && (<Delete onClick={onMetricDeleteHandle} type="button" title="Delete Metric">
          <TrashBlueIcon />
        </Delete>)}
      </Head>

      <Accordion.Collapse as={Body} eventKey={id}>
        <BodyInner>
          <Group>
            <Label>Label</Label>
            <InputField size="md" value={labelName} onChange={onLabelChangeHandle} onBlur={onLabelBlurHandle} placeholder="Label Name" ref={$label} required />
          </Group>

          {(units.filter(unit => !unit.isDeleted).length || isUnitCreation) ? <Group>
            <Label>Unit</Label>

            {units.filter(unit => !unit.isDeleted).map(unit => (
              <InputField size="md" value={isUnitEdit === unit.id ? unitName : unit.name} onChange={onUnitChangeHandle} onFocus={onUnitFocusHandle(unit)} onBlur={onUnitBlurHandle(unit)} placeholder="Unit Name" required key={unit.id} suffix={contentSuffix(unit)} />
            ))}

            {isUnitCreation
              ? <InputField size="md" value={unitName} onChange={onNewUnitChangeHandle} onBlur={onNewUnitBlurHandle} ref={$unit} placeholder="Unit Name" required suffix={contentSuffix(0)} />
              : null
            }
          </Group> : null}

          <ActionsGroup>
            <Button type="button" styleType="link-primary" disabled={isUnitCreation} onClick={onAddUnitHandle}>Add Unit</Button>
          </ActionsGroup>
        </BodyInner>
      </Accordion.Collapse>
    </Wrapper>
  );
}

export default MetricGroup;
