import React, { useEffect, useMemo, useState, useRef } from "react";
import { Col, Modal, Spin } from "antd";
import { Tooltip } from "elements";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from 'react-to-print';

import { ReactComponent as Tooltipicon } from "./tooltip.svg";

import StatmentIcon from "assets/svg/statmentIcon.svg";
import StatmentGrayIcon from "assets/svg/statmentGrayIcon.svg";
import StatmentBlueIcon from "assets/svg/StatmentIconBlue.svg";
import Right from "assets/svg/right-arrow.svg";

import Steps from "components/Journeys/StepsHeader";
import MissionButton from "atoms/MissionElement/Button";
import MissionSelect from "atoms/MissionElement/MissionSelect/MIssionSelect";
import SelectStatment from "atoms/MissionElement/SelectStatment/SelectStatment";
import EditableInput from "atoms/MissionElement/Input";
import MissionDropDown from "atoms/MissionElement/MissionDropDown/MissionDropDown";
import DeleteModal from "atoms/MissionElement/DeleteModal/DeleteModal";

import CustomOption from './components/CustomOption';
import PrintHead from './components/PrintHead';

import {
  GetMissionStatement,
  SetMissionStatement,
} from "Redux/GetMissionStatement/action";
import {
  MissionStatement,
  SetCreateMissionStatement,
} from "Redux/CreateMissionStatement/action";
import {
  SetUpdateMissionStatement,
  UpdateMissionStatement,
} from "Redux/UpdateMissionStatement/action";
import { DeleteRoles } from "Redux/DeleteRoles/action";
import { DeleteValues } from "Redux/DeleteValues/action";
import { items, steps } from "utils/utility";
import { DeleteMissionStatement } from "Redux/DeleteMissionStatement/action";

import { GetRoles, CreateCustomRole } from "Redux/GetRoles/action";
import { GetValues, CreateCustomValue } from "Redux/GetValues/action";

import ExitConfirmModal from "atoms/MissionElement/ExitConfirmModal";

import { getCookie } from "utils/StorageVariables";

import {
  Container,
  Content,
  Header,
  Image,
  Heading,
  StatmentContent,
  LargeHeading,
  Text,
  CustomButton,
  RolesContainer,
  RolesContent,
  MainRoleDiv,
  MissionArea,
  StepsWrapper,
  TextsWrapper,
  XlargeHeading,
  RolesText,
  SmallText,
  SelectWrapper,
  InputWrapper,
  AiSugesstionText,
  Circle,
  RolesHeading,
  MissionHeading,
  RoleHeading,
  RolesSpan,
  DropdownWrapper,
  Wrapper,
  EditStatmentButton,
  CustomTable,
  CustomRow,
  RowHeading,
  RowText,
  MissionComplete
} from "./styled";

import styles from "./styles.module.scss";

import AIHelper from "./components/AIHelper";

const isUUID = (value) => {
  const regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
  return regex.test(value);
}

const MissionStatment = ({
  isShow,
  setisShow,
  missionStatmentGoal,
  setMissionStatementGoal,
}) => {
  const dispatch = useDispatch();
  let domainName = getCookie("domainName");

  const { data: roleOption } = useSelector((state) => state.getRolesReducer);
  const { data: valueOption } = useSelector((state) => state.getValuesReducer);

  useEffect(() => {
    dispatch(GetRoles());
  }, []);

  const [currentStep, setCurrentStep] = useState(1);
  const [edit, setIsEdit] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [isConfirmExit, setIsConfirmExit] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [deleteOpenModal, setDeleteOpenModal] = useState(false);
  const [missionStatementData, setMissionStatementData] = useState({
    roles: [],
    values: [],
  });

  const [rolesToDelete, setRolesToDelete] = useState([]);
  const [valuesToDelete, setValuesToDelete] = useState([]);

  const [customData, setCustomData] = useState({
    roles: [],
    values: []
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [continueDisabled, setContinueDisabled] = useState(false);
  const [valueLength, setValueLength] = useState(0);

  const { loading: deleteMissionloading } = useSelector(
    (state) => state.deleteMissionStatementReducer
  );

  const { missionStatement, loading: getMissionStatementLoading } = useSelector(
    (state) => state.getMissionStatementReducer
  );

  const { loading } = useSelector(
    (state) => state.createMissionStatementReducer
  );

  const { loading: updateMissionStatementLoading } = useSelector(
    (state) => state.updateMissionStatementReducer
  );

  const $componentRef = useRef();
  const handlePrint = useReactToPrint({
    documentTitle: "Mission Statement",
    content: () => $componentRef.current,
  });

  useEffect(() => {
    if (!missionStatmentGoal) return;
    if (missionStatmentGoal?.hasMissionStatement) {
      dispatch(GetMissionStatement(missionStatmentGoal?.goal));
    }
  }, [missionStatmentGoal]);

  useEffect(() => {
    return () => {
      dispatch(SetMissionStatement(null));
      dispatch(SetUpdateMissionStatement(null));
      dispatch(SetCreateMissionStatement(null));
    };
  }, []);

  useEffect(() => {
    setisLoading(
      loading || getMissionStatementLoading || updateMissionStatementLoading
    );
  }, [loading, getMissionStatementLoading, updateMissionStatementLoading]);

  useEffect(() => {
    if (currentStep === 2)
      dispatch(GetValues(missionStatementData.roles.map(({ value }) => value)));
  }, [currentStep, missionStatementData.roles]);

  const roles = useMemo(() => {
    const missionStatementRoles = missionStatement?.roles
      ? missionStatement?.roles.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      : [];

    const missionStatementMap = new Map(
      missionStatementRoles.map((item) => [item.label.toLowerCase(), item])
    );

    const updatedRoleOptions = roleOption.map((staticRole) => {
      const linkedRole = missionStatementMap.get(
        staticRole.label.toLowerCase()
      );

      return {
        ...staticRole,
        linked_role: linkedRole ? linkedRole.value : null,
      };
    });

    return [...updatedRoleOptions];
  }, [missionStatement?.roles, roleOption]); // dependency array

  const Values = useMemo(() => {
    // Remove duplicates from valueOption based on ID
    const uniqueValueOptions = valueOption.filter(
      (value, index, self) => 
        index === self.findIndex((v) => v.value === value.value)
    );
  
    const missionStatementValues = missionStatement?.values
      ? missionStatement?.values.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      : [];
  
    const missionStatementMap = new Map(
      missionStatementValues.map((item) => [item.label.toLowerCase(), item])
    );
  
    const updatedValueOptions = uniqueValueOptions.map((staticValue) => {
      const linkedValue = missionStatementMap.get(staticValue.label.toLowerCase());
      return {
        ...staticValue,
        linked_value: linkedValue ? linkedValue.value : null,
      };
    });
  
    // Create a set of labels from the updatedValueOptions to avoid duplicate items
    const updatedLabelsSet = new Set(updatedValueOptions.map((option) => option.label.toLowerCase()));
  
    // Filter the original items that are not present in the updatedLabelsSet
    const originalItems = missionStatementValues.filter(
      (item) => !updatedLabelsSet.has(item.label.toLowerCase())
    );
  
    return [...updatedValueOptions, ...originalItems];
  }, [missionStatement?.values, valueOption]);  

  useEffect(() => {
    if (!missionStatement) return;

    const missionStatementCopy = { ...missionStatement };

    setMissionStatementData({
      ...missionStatement,
      roles: missionStatementCopy?.roles.map((item) => {
        const role = roles.find((r) => r.label === item.name);

        return {
          value: role ? role.value : item.id,
          id: item.id,
          label: item.name,
        };
      }),
      values: missionStatementCopy?.values.map((item) => {
        const value = Values.find((v) => v.label === item.name);

        return {
          value: value ? value.value : item.id,
          id: item.id,
          label: item.name,
        };
      }),
    });
  }, [missionStatement, roles]);

  const handleStepChange = (step) => {
    setCurrentStep(step);
  };

  const handleChange = (val, step) => {
    if (val.length > 255) {
      setErrorMessage("Ensure this field has no more than 255 characters.");
      setContinueDisabled(true);
    } else {
      setErrorMessage(""); // Clear the error message if the input is valid
      setContinueDisabled(false);
    }

    switch (step) {
      case 3:
        setMissionStatementData((prevData) => ({
          ...prevData,
          principle: { ...prevData?.principle, name: val },
        }));
        break;
      case 4:
        setMissionStatementData((prevData) => ({
          ...prevData,
          code_of_conduct: { ...prevData?.code_of_conduct, name: val },
        }));
        break;
      case 5:
        setMissionStatementData((prevData) => ({
          ...prevData,
          purpose: { ...prevData?.purpose, name: val },
        }));
        break;
      case 6:
        setMissionStatementData((prevData) => ({
          ...prevData,
          vision: {
            ...prevData?.vision,
            name: val,
          },
        }));
        break;

      default:
        break;
    }
  };

  const nextStep = (item) => {
    const nextStep = currentStep + 1;

    if (nextStep <= items?.length) {
      handleStepChange(nextStep);
      setIsEdit(item?.title);
    }
  };

  const handlePrev = () => {
    if (currentStep < 2) return;
    const nextStep = currentStep - 1;
    const prevItem = currentStep - 2;
    items.filter((item) => item?.step === prevItem && setIsEdit(item?.title));
    if (nextStep <= items?.length) {
      handleStepChange(nextStep);
    }
  };

  const handleSave = async (item, shouldClose = true) => {
    if (missionStatementData && missionStatementData[item?.key]?.name) {
      setValueLength(missionStatementData[item?.key]?.name.length);
    }

    if (item?.step < 6) return nextStep(item);

    if (item?.step === 6) {
      const newRoles = missionStatementData?.roles?.filter(
        (role) => role.isNew
      );
      const newValues = missionStatementData?.values?.filter(
        (value) => value.isNew
      );

      const rolesToSave = newRoles.map(({ label }) => ({ name: label }));
      const valuesToSave = newValues.map(({ label }) => ({ name: label }));

      setIsEdit(item?.title);

      if (missionStatmentGoal?.hasMissionStatement) {
        await onDelete();

        return dispatch(
          UpdateMissionStatement(
            {
              ...missionStatementData,
              roles: rolesToSave,
              values: valuesToSave,
              goal: missionStatmentGoal?.goal,
            },
            missionStatementData?.id,

            setCurrentStep
          )
        );
      }

      return dispatch(
        MissionStatement(
          {
            ...missionStatementData,
            roles: rolesToSave,
            values: valuesToSave,
            goal: missionStatmentGoal?.goal,
          },
          setMissionStatementGoal,
          missionStatmentGoal,

          setCurrentStep
        )
      );
    }

    if (shouldClose) return handleClose();
  };

  const onDelete = async () => {
    const deleteRolesPromises = rolesToDelete.map((role) => {
      return new Promise((resolve, reject) => {
        dispatch(
          DeleteRoles(role, () => {
            resolve(true);
          })
        );
      });
    });

    const deleteValuesPromises = valuesToDelete.map((value) => {
      return new Promise((resolve, reject) => {
        dispatch(
          DeleteValues(value, () => {
            resolve(true);
          })
        );
      });
    });

    try {
      await Promise.all(deleteRolesPromises);
      await Promise.all(deleteValuesPromises);

      setRolesToDelete([]);
      setValuesToDelete([]);

      return true;
    } catch (error) {
      console.error("Error deleting roles:", error);
      return false;
    }
  };

  const handleSelect = (key, value) => {
    if (value && key === "roles") {
      const roleData = roles.find((role) => role.value === value);

      setMissionStatementData((prevData) => ({
        ...prevData,
        roles: [...prevData.roles, { ...roleData, isNew: true }],
      }));
    } else if (value && key === "values") {
      const valueData = Values.find((val) => val.value === value);

      setMissionStatementData((prevData) => ({
        ...prevData,
        values: [...prevData.values, { ...valueData, isNew: true }],
      }));
    }
  };

  const handleAISelect = (value, step) => {
    const item = { label: value.text, value: value.text };

    if (step > 2) {
      handleChange(value.text, step);
    } else {
      const type = step === 1 ? "roles" : "values";

      setCustomData((prevData) => ({
        ...prevData,
        [type]: [...prevData[type], { ...item, isNew: true }],
      }));
    
      setMissionStatementData((prevData) => {
        const exists = prevData[type].some(i => i.label === value.text);

        if (exists) {
          return {
            ...prevData,
            [type]: prevData[type].filter(i => i.label !== value.text),
          };
        } else {
          return {
            ...prevData,
            [type]: [...prevData[type], { ...item, isNew: true }],
          };
        }
      });
    }
  }

  const handleDelete = (key, value) => {
    if (value && key === "roles") {
      const userRole = roles.find((role) => role.value === value);

      if (userRole?.linked_role) {
        setRolesToDelete([...rolesToDelete, userRole?.linked_role]);
      }

      setMissionStatementData((prevData) => ({
        ...prevData,
        roles: prevData.roles.filter((role) => role.value !== value),
      }));

      setCustomData((prevData) => ({
        ...prevData,
        roles: prevData.roles.filter((role) => role.value !== value),
      }));
    } else if (value && key === "values") {
      const userValue = Values.find((val) => val.value === value);

      if (userValue?.linked_value) {
        setValuesToDelete([...valuesToDelete, userValue?.linked_value]);
      }
      else if (userValue?.value && isUUID(userValue?.value)) {
        setValuesToDelete([...valuesToDelete, userValue?.value]);
      }

      setMissionStatementData((prevData) => ({
        ...prevData,
        values: prevData.values.filter((val) => val.value !== value),
      }));

      setCustomData((prevData) => ({
        ...prevData,
        values: prevData.values.filter((val) => val.value !== value),
      }));
    }
  };

  const handleCreate = (key) => (value) => {
    const type = key;

    const formData = new FormData();
    formData.append('name', value);

    if (type === 'roles') {
      dispatch(CreateCustomRole(formData, handleCreated(type)));
    }
    else {
      dispatch(CreateCustomValue(formData, handleCreated(type)));
    }
  }

  const handleCreated = type => data => {
    setTimeout(() => {
      setMissionStatementData((prevData) => ({
        ...prevData,
        [type]: [...prevData[type], { value: data.value, label: data.label, custom: true, isNew: true }],
      }));
    }, 100);
  }

  const deleteMissionstatement = () => {
    dispatch(
      DeleteMissionStatement(
        missionStatement.id,
        handleClose
      )
    );
  };

  const handleClose = () => {
    setMissionStatementData(null);
    setMissionStatementGoal(null);
    setIsEditing(false);
    setisLoading(false);
    setDeleteOpenModal(false);
  };

  const handleConfirmModal = () => {
    if (isEditing && currentStep < 7) {
      setIsConfirmExit(true);
    } else {
      setisShow(false);
    }
  };

  let disableNextSteps = false;

  const dropdownSteps = steps.map((step, index) => {
    let disabled = disableNextSteps;

    // Determine if the current step should disable the subsequent steps
    if (!disableNextSteps) {
      switch (step.value) {
        case 1:
          disableNextSteps = !missionStatementData?.roles?.length;
          break;
        case 2:
          disableNextSteps = !missionStatementData?.values?.length;
          break;
        case 3:
          disableNextSteps = !missionStatementData?.principle?.name;
          break;
        case 4:
          disableNextSteps = !missionStatementData?.code_of_conduct?.name;
          break;
        case 5:
          disableNextSteps = !missionStatementData?.purpose?.name;
          break;
        case 6:
          disableNextSteps = !missionStatementData?.vision?.name;
          break;
        default:
          break;
      }
    }

    return { ...step, disabled };
  });

  return (
    <Container>
      <Modal
        bodyStyle={{
          padding: "0",
          height: "100%",
          overflow: 'hidden'
        }}
        contentStyle={{
          height: "100%",
        }}
        className={styles.modal}
        width={"600px"}
        height={"calc(100vh - 90px)"}
        style={{
          maxHeight: "700px",
        }}
        centered
        footer={null}
        open={true}
        maskClosable={false}
        onCancel={handleConfirmModal}
      >
        <Content>
          <Header isheader>
            <Image src={StatmentIcon} />
            <Heading>MISSION STATEMENT</Heading>
          </Header>

          {isLoading && (
            <>
              <Header isheader>
                <RolesHeading>
                  <Circle>{missionStatmentGoal?.priority} </Circle>{" "}
                  <RoleHeading>{missionStatmentGoal?.area} </RoleHeading>
                  <RolesSpan>{missionStatmentGoal?.category}</RolesSpan>
                </RolesHeading>
              </Header>
              <StatmentContent isHeight>
                <Image src={StatmentBlueIcon} />
                <LargeHeading className="mb-2">
                  Generating your mission statement
                </LargeHeading>
                <Spin />
              </StatmentContent>
              {/* <Footer>
                <FooterHeading>
                  <Image src={Stars} />
                  AI Assistant <Span>Beta</Span>
                </FooterHeading>
              </Footer> */}
            </>
          )}

          {missionStatmentGoal?.hasMissionStatement && !isLoading && (currentStep === 7 || currentStep === 1 && !isEditing) && (
            <Header isBetween isheader>
              <RolesHeading>
                <Circle>{missionStatmentGoal?.priority}</Circle>{" "}
                <RoleHeading>{missionStatmentGoal?.area} </RoleHeading>
                <RolesSpan isEllipse>
                  {missionStatmentGoal?.category}
                </RolesSpan>
              </RolesHeading>
              <DropdownWrapper>
                {/* ////////// */}
                <MissionDropDown
                  // deleteOpenModal={deleteOpenModal}
                  setDeleteOpenModal={setDeleteOpenModal}
                  onExport={handlePrint}
                  // onClick={deleteMissionstatement}
                />
              </DropdownWrapper>
            </Header>
          )}

          {missionStatmentGoal?.hasMissionStatement &&
            !isLoading && (currentStep === 7 || currentStep === 1 && !isEditing) && 
            /* !isEditing && */ (
              <>
                <MissionComplete ref={$componentRef}>
                  <PrintHead />

                  <RolesHeading $printable>
                    <Circle>{missionStatmentGoal?.priority}</Circle>{" "}
                    <RoleHeading>{missionStatmentGoal?.area} </RoleHeading>
                    <RolesSpan isEllipse>{missionStatmentGoal?.category}</RolesSpan>
                  </RolesHeading>

                  <MissionHeading>Mission Statment</MissionHeading>
                  <AiSugesstionText isLight>
                    {missionStatementData?.conclusion}
                  </AiSugesstionText>
                  <CustomTable>
                    <CustomRow isRow>
                      <Col span={11}>
                        <RowHeading>Roles</RowHeading>

                        <RowText>
                        {missionStatementData?.roles
                          ?.map((value) => value?.label)
                          .join(", ")}
                        </RowText>
                      </Col>
                      <Col span={11}>
                        <RowHeading>Values</RowHeading>
                        <RowText>
                          {/* {missionStatementData?.values.toString()} */}
                          {missionStatementData?.values
                            ?.map((value) => value?.label)
                            .join(", ")}
                        </RowText>
                      </Col>
                    </CustomRow>
                    <CustomRow>
                      <RowHeading>Principles</RowHeading>
                      <RowText>{missionStatementData?.principle?.name}</RowText>
                    </CustomRow>
                    <CustomRow>
                      <RowHeading>Code of Conduct</RowHeading>
                      <RowText>
                        {missionStatementData?.code_of_conduct?.name}
                      </RowText>
                    </CustomRow>
                    <CustomRow>
                      <RowHeading>Purpose (Statement)</RowHeading>
                      <RowText>{missionStatementData?.purpose?.name}</RowText>
                    </CustomRow>
                    <CustomRow isBorderNone>
                      <RowHeading>Vision (Phrase)</RowHeading>
                      <RowText>{missionStatementData?.vision?.name}</RowText>
                    </CustomRow>
                  </CustomTable>
                </MissionComplete>
                <Wrapper isBorder>
                  {!isEditing ? <MissionButton
                    onClick={() => setIsEditing(true)}
                    text={"Edit"}
                  /> : 
                  <MissionButton text={"Close"} onClick={handleClose} />}
                </Wrapper>
              </>
            )}

          {!missionStatmentGoal?.hasMissionStatement &&
            !isEditing &&
            !isLoading && (
              <>
                <StatmentContent>
                  <Image src={StatmentGrayIcon} />
                  <LargeHeading>Mission Statement Generator</LargeHeading>
                  <Text>
                    Your mission statement is the first step in designing your ideal path. It acts as a compass guiding you on your journey to achieving your goals.<br /><br />
                    This tool will help you create a mission statement that transforms your dreams into plans and passions into progress.
                  </Text>
                  <CustomButton onClick={() => setIsEditing(true)}>
                    Create Mission Statement
                  </CustomButton>
                </StatmentContent>
                {/* <Footer>
                <FooterHeading>
                  <Image src={Stars} />
                  AI Assistant <Span>Beta</Span>
                </FooterHeading>
              </Footer> */}
              </>
            )}

          {isEditing && !isLoading && currentStep < 7 && (
            <RolesContainer>
              {isEditing && (<Header isBetween isheader>
                {currentStep > 1 && (
                  <>
                    <EditStatmentButton onClick={handlePrev}>
                      <Image src={Right} />
                      Edit {edit}
                    </EditStatmentButton>
                    <SelectStatment
                      options={dropdownSteps}
                      value={currentStep}
                      onChange={(value) => {
                        setCurrentStep(value);

                        if (value === 7) {
                          handleSave(items[items.length - 2], false);
                        }
                      }}
                    />
                  </>
                )}
              </Header>)}

              <RolesContent>
                {currentStep < 7 && <MainRoleDiv>
                  <Circle>{missionStatmentGoal?.priority}</Circle>{" "}
                  <MissionArea>{missionStatmentGoal?.area} </MissionArea>
                  <RolesSpan>{missionStatmentGoal?.category}</RolesSpan>
                </MainRoleDiv>}

                {currentStep < 7 && <StepsWrapper>
                  <Steps notShow items={items} step={currentStep} />
                </StepsWrapper>}

                {items?.map(
                  (item, i) =>
                    currentStep === item?.step && (
                      <>
                        <TextsWrapper key={i}>
                          <XlargeHeading>
                            {item?.title}{" "}
                            {item?.step !== 7 && (
                              <Tooltip
                                placement="rightTop"
                                type="secondary"
                                title={item?.tooltipDesc}
                                customOverlayClassName={styles.tooltip}
                                align={{
                                  offset: [-2, 6],
                                }}
                              >
                                <Tooltipicon style={{ marginTop: "4px" }} />
                              </Tooltip>
                            )}{" "}
                          </XlargeHeading>

                          {item?.step !== 7 && (
                            <>
                              <RolesText>{item?.disc}</RolesText>
                              <SmallText>{item?.suggestion}</SmallText>{" "}
                            </>
                          )}
                        </TextsWrapper>

                        {item?.step !== 7 && (
                          <SelectWrapper>
                            {item?.step < 3 ? (
                              <MissionSelect
                                onSelect={(rest) =>
                                  handleSelect(item?.key, rest)
                                }
                                onDeselect={(rest) =>
                                  handleDelete(item?.key, rest)
                                }
                                onCreate={handleCreate(item?.key)}
                                text={item?.title}
                                placeholder={item?.placeholder}
                                value={
                                  missionStatementData &&
                                  missionStatementData[item?.key]
                                }
                                option={[
                                  {
                                    label: "",
                                    options: [
                                      ...(((item?.step == 1 && roles) || (item?.step == 2 && Values)).filter(i => !i.custom))
                                    ]
                                  },
                                  {
                                    label: item?.step == 1 ? "Custom Roles" : "Custom Values",
                                    options: [
                                      ...(((item?.step == 1 && roles) || (item?.step == 2 && Values))
                                        .filter(i => i.custom)
                                        .map(i => ({ ...i, label: <CustomOption value={i.value} label={i.label} type={item?.step == 1 ? 'roles' : 'values'} />, originalLabel: i.label })))
                                    ]
                                  }
                                ]}
                                error={missionStatementData && missionStatementData[item?.key].length > 3 ? item.error : null}
                              />
                            ) : (
                              <InputWrapper>
                                <EditableInput
                                  text={item?.label}
                                  value={
                                    missionStatementData &&
                                    missionStatementData[item?.key]?.name
                                  }
                                  onChange={(e) => {
                                    handleChange(e.target.value, item?.step);
                                  }}
                                  isEditing={true}
                                  placeholder={item?.step == 3 ? item?.placeholder.replace('{role}', missionStatementData['roles'][0].label.toLowerCase()) : item?.placeholder}
                                  errorMessage={errorMessage}
                                />
                              </InputWrapper>
                            )}
                          </SelectWrapper>
                        )}

                        {item?.step === 7 && (
                          <>
                            {/* <AiSugesstionText>
                              {missionStatementData?.conclusion}
                            </AiSugesstionText> */}
                            {/* <Wrapper>
                              <RegGernerateButton>
                                📝 Enhance
                              </RegGernerateButton>
                              <RegGernerateButton>
                                🎵 Change Tone <Image src={DowIcon} />
                              </RegGernerateButton>
                            </Wrapper> */}
                          </>
                        )}

                        {item?.step === 7 ? (
                          <MissionButton text={"Close"} onClick={handleClose} />
                        ) : (
                          <MissionButton
                            marginTop={"-0.5rem"}
                            text={isLoading ? "Loading" : "Continue"}
                            onClick={() => handleSave(item)}
                            disabled={
                              currentStep > 2
                                ? !missionStatementData[item?.key]?.name || continueDisabled
                                : !missionStatementData[item?.key]?.length || missionStatementData[item?.key]?.length > 3 || isLoading
                            }
                          />
                        )}
                      </>
                    )
                )}

                {currentStep < 7 ? <AIHelper
                  step={currentStep}
                  missionStatement={missionStatementData}
                  goal={{
                    area: missionStatmentGoal?.area,
                    category: missionStatmentGoal?.category,
                    domain: domainName,
                  }}
                  onSelect={handleAISelect}
                /> : null}
              </RolesContent>
            </RolesContainer>
          )}
        </Content>
      </Modal>

      {deleteOpenModal && (
        <DeleteModal
          missionStatmentGoal={missionStatmentGoal}
          deleteOpenModal={deleteOpenModal}
          setDeleteOpenModal={setDeleteOpenModal}
          onClick={deleteMissionstatement}
          deleteMissionloading={deleteMissionloading}
          title={"Delete Mission Statement"}
          description={"Are you sure you want to delete Mission statement for"}
        />
      )}

      <ExitConfirmModal
        isOpen={isConfirmExit}
        onOK={handleClose}
        onCancel={() => setIsConfirmExit(false)}
      />
    </Container>
  );
};

export default MissionStatment;
