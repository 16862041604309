import { FC } from 'react';

// @ts-ignore
import profilePlaceholderIcon from "assets/images/priorities/profile-placeholder.svg";

import {
  Wrapper,
  ProfilePicture,
  Name,
  Email,
  Link
} from "./styled";

import { APM_PATH, COACHING_PATH } from 'utils/routes';

export interface IClient {
  id: string;
  name: string;
  email: string;
}

interface IProps {
  data: IClient;
}

const UserRow: FC<IProps> = ({ data }) => {
  const { id, name, email } = data;

  return (
    <Wrapper>
      <ProfilePicture src={profilePlaceholderIcon} alt="" />
      <Name>{name}</Name>
      <Email>{email}</Email>

      <Link to={`${APM_PATH}${COACHING_PATH}/${id}`}>Enter</Link>
    </Wrapper>
  );
}

export default UserRow;
