import React, { useState } from "react";
import styled from "styled-components";

import SubHeader from "components/Scheduler/SubHeader";
import TableSettingsHeader, {
  SIMPLE_VIEW_TABS,
} from "components/Scheduler/TableSettingsHeader";

import InflurnceChartModal from "components/PriorityApp/SimpleView/ReturnFactorModal";
import SimpleView from "components/PriorityApp/SimpleView";
import ClientsListHeader from "components/PriorityApp/ClientsListHeader";

const Priorities = () => {
  const [activeTab, setActiveTab] = useState(SIMPLE_VIEW_TABS.All);
  const [isChartOpen, setIsChartOpen] = useState(false);
  const [ipadView, setIpadView] = useState(false);
  const [background, setBackground] = useState(false);

  const onTabChangeHandle = (key) => {
    setActiveTab(key);
  };

  return (
    <MainContainer>
      <InnerContainer>
        <ClientsListHeader />

        <SubHeader
          title={"Priority Dashboard"}
          // isProView={isProView}
          setIsChartOpen={setIsChartOpen}
          isChartOpen={isChartOpen}
          // onViewChange={() => setIsProView(!isProView)}
          setIpadView={setIpadView}
          ipadView={ipadView}
          setBackground={setBackground}
        />

        {/* {screenSize === "iPad" && (
          <Progres>
            <ProgressBar />
            <Targt>
              <Target onClick={() => {
                setIpadView(true);
                setBackground(true);
              }} />
            </Targt>
          </Progres>
        )} */}

        <TableSettingsHeader
          isProView={false}
          tab={activeTab}
          onTabChange={onTabChangeHandle}
        />

        <SimpleView
          isFocusedOnly={activeTab === SIMPLE_VIEW_TABS.Focused}
          isViewOnly={activeTab === SIMPLE_VIEW_TABS.All}
          // isProView={isProView}
          ipadView={ipadView}
          setIpadView={setIpadView}
          setBackground={setBackground}
        />

        {/* {isProView ? (
          <DataTable />
        ) : (
          <SimpleView
            isFocusedOnly={activeTab === SIMPLE_VIEW_TABS.Focused}
            isViewOnly={activeTab === SIMPLE_VIEW_TABS.All}
            isProView={isProView}
            ipadView={ipadView}
            setIpadView={setIpadView}
            setBackground={setBackground}
          />
        )} */}
      </InnerContainer>
      {isChartOpen && (
        <InflurnceChartModal
          setIsChartOpen={setIsChartOpen}
          isChartOpen={isChartOpen}
        />
      )}
      {background && (
        <Background
          onClick={() => {
            setBackground(false);
            setIpadView(false);
          }}
          $active={background}
        />
      )}
    </MainContainer>
  );
};

export default Priorities;

const MainContainer = styled.div`
  display: flex;
  height: calc(100vh - 60px - 50px);
`;

const InnerContainer = styled.div`
  background-color: #eeeff5;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Progres = styled.div`
  position: absolute;
  right: 20px;
  z-index: 99;
  top: 63px;
  display: flex;
`;

const Targt = styled.div`
  margin-left: 15px;
  cursor: pointer;
`;

const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(79, 89, 102, 0.5);
  top: 0;
  left: 0;
  z-index: 8;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s, visibility 0.25s;

  ${({ $active }) =>
    $active &&
    `
    opacity: 1;
    visibility: visible;
    z-index: 9999999;
  `};
`;
