import { ActionTypes, ListFocusedExtremesState } from './type';

const initial = {
  data: {
    most_time_consuming: [],
    least_time_consuming: [],
    least_priority: []
  },
  popup: null,
  loading: false
} as ListFocusedExtremesState;

export default (state = initial, action: any) => {
  switch (action.type) {
    case ActionTypes.REQUEST:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.SUCCESS:
      return {
        ...state,
        data: action.payload || [],
        loading: false
      };
    case ActionTypes.FAILURE:
      return {
        ...state,
        loading: false
      };

    case ActionTypes.OPEN:
      return {
        ...state,
        popup: action.payload
      };
    case ActionTypes.CLOSE:
      return {
        ...state,
        popup: null
      };
    default:
      return state;
  }
}
