import { ActionTypes } from './type'

const initialPrioritizedActionsState = {
    prioritizedData: [],
    prioritizedDataGrouped: [],
    length: 0,
    loading: false,
    filters: {
        journeys: [],
        filterByType: [],
        areas: [],
        order: '',
        sort: ''
    },
    simpleView: false,
    client: null
};

export default (state = initialPrioritizedActionsState, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_LIST_PRIORITIZED_ACTIONS_REQUEST:
            return { ...state, loading: true };
        case ActionTypes.FETCH_LIST_PRIORITIZED_ACTIONS_SUCCESS:
            return { ...state, prioritizedData: action.payload, length: action.length, loading: false };
        case ActionTypes.FETCH_LIST_PRIORITIZED_ACTIONS_FAILURE:
            return { ...state, loading: false };

        case ActionTypes.FETCH_LIST_PRIORITIZED_ACTIONS_GROUPED_REQUEST:
            return { ...state, loading: true };
        case ActionTypes.FETCH_LIST_PRIORITIZED_ACTIONS_GROUPED_SUCCESS:
            return { ...state, prioritizedDataGrouped: action.payload, length: action.length, loading: false };
        case ActionTypes.FETCH_LIST_PRIORITIZED_ACTIONS_GROUPED_FAILURE:
            return { ...state, loading: false };

        case ActionTypes.MUTATE_PRIORITIZED_VALUES: {
            // pick key, and value to update from given payload for prioritizedData and mutate that key 
            const { key, value } = action.payload;
            const prioritizedData = state.prioritizedData.map(item => {
                if (item.id === key) {
                    return { ...item, ...value };
                }
                return item;
            });
            return { ...state, prioritizedData };
        }
        case ActionTypes.UPDATE_PRIORITIZED_VALUES: {
            // pick key, and value to update from given payload for prioritizedData and mutate that key 
           const { journeys, areas, order,filterByType, sort } = action.payload;
            const filters = {...state.filters,
                journeys,
                areas,
                filterByType,
                order,
                sort
            };

            return { ...state, filters };
        }
        case ActionTypes.TOGGLE_VIEW: {
            return {
                ...state,
                simpleView: !state.simpleView
            };
        }
        case ActionTypes.SET_CLIENT: {
            return {
                ...state,
                client: action.payload,
                loading: true
            };
        }
        default:
            return state;
    }
}
